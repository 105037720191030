import { Time } from "@angular/common";
import { SessionModel } from "./session.model";

export class EventResponseModel {
    count: number
    next: string
    previous: string
    results: [EventModel]
}

export class EventModel {

    public constructor(init?: Partial<EventModel>) {
        Object.assign(this, init);
    }
    
    id: number
    name: string

    created_at: Date
    description: string

    event_agenda_url: string
    event_faqs_url: string
    event_format_url: string
    event_panel_sessions_url: string
    event_sponsors_url: string

    thumbnail: string

    no_registration_required: boolean
    slug: string
    updated_at: string

    session: [Number]
    session_details: [SessionModel]
    event_date:Date
    start_at: Time | null
    end_at: Time | null
    timezone: number

}

export class Timezone{
    id: number
    name: string
    created_at: Date
}

export class TimezoneResponseModel {
    count: number
    next: string
    previous: string
    results: [Timezone]
}

export class Template{
    id: number
    template_name:string
    front_image:File
    back_image:File
}

export class TemplateResponseModel {
    count: number
    next: string
    previous: string
    results: [Template]
}

export class AdmitCard{
    id: number
    template:number
    event:number
    issued:boolean
}