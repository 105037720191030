<div class="modal fade" id="AddTemplate" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="AddTemplateLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <p>Issue Admit Card </p>
            <button type="button" #cardModalCloseBtn class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeClicked()"></button>
        </div>
        <div class="modal-body">

            <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckChecked">Issued</label>
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [(ngModel)]="issued" [checked]="issued">
            </div>
            
            <div class="mb-3">
                <label for="name" class="mt-3" >Template</label>
                <ng-select placeholder="Select Template" [(ngModel)]="eventTmplate" (ngModelChange)="setImages()">
                    <ng-option *ngFor="let template of templateResponseModel.value.results" [value]="template.id" >
                    <span class="text-capitalize">{{template.template_name}}</span> </ng-option>
                </ng-select>
            </div>
            <div class="mb-3 d-flex justify-content-center ">
                <div class="image-container" *ngIf="templateFrontImage">
                    <img src="{{templateFrontImage}}" alt="templateFrontImage" class="image">
                </div>

                <div class="image-container" *ngIf="templateBackImage">
                    <img src="{{templateBackImage}}" alt="templateFrontImage" class="image">
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" id="admitcardModalCloseBtn" (click)="closeClicked()"
         class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
            <button  type="button" class="btn btn-primary" (click)="createOrUpdateAdmitCard()">Save</button>

        </div>
    </div>
</div>
</div>
