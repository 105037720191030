import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { Page404Component } from './page404/page404.component';
import { HeaderComponent } from './header/header.component';
import { EventCardComponent } from './event-card/event-card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ParticipantCardComponent } from './participant-card/participant-card.component';
import { RouterModule } from '@angular/router';
import { ModalCompanyFormComponent } from './modal-company-form/modal-company-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalParticipantFormComponent } from './modal-participant-form/modal-participant-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModelEventFormComponent } from './model-event-form/model-event-form.component';
import { ModelSessionFormComponent } from './model-session-form/model-session-form.component';
import { ModalImportEventDataComponent } from './modal-import-event-data/modal-import-event-data.component';
import { EmptyListDataComponent } from './empty-list-data/empty-list-data.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { ModalAddToEventComponent } from './modal-add-to-event/modal-add-to-event.component';
import { ModalAdmitTemplateComponent } from './modal-admit-template/modal-admit-template.component';


@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    EventCardComponent,
    BreadcrumbComponent,
    ParticipantCardComponent,
    ModalCompanyFormComponent,
    AlertComponent,
    ModalDeleteComponent,
    ModalParticipantFormComponent,
    ModelEventFormComponent,
    ModelSessionFormComponent,
    ModalImportEventDataComponent,
    EmptyListDataComponent,
    HeaderSearchComponent,
    ModalAddToEventComponent,
    ModalAdmitTemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    EventCardComponent,
    BreadcrumbComponent,
    ParticipantCardComponent,
    ModalCompanyFormComponent,
    AlertComponent,
    ModalDeleteComponent,
    ModalParticipantFormComponent,
    ModelEventFormComponent,
    ModelSessionFormComponent,
    ModalImportEventDataComponent,
    EmptyListDataComponent,
    HeaderSearchComponent,
    ModalAddToEventComponent,
    ModalAdmitTemplateComponent
  ]
})
export class ComponentsModule { }
