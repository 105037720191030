export class ApiCollection {
    static Participant: String = "/event_master/participant";
    static Participant_Event_Detail: String = "/event_master/participant_event_detail";
    static Participant_Event_Detail_Retrieve: String = "/event_master/participant_event_detail_retrieve";     
    static Event: String = "/event_master/event";
    static Session: String = "/event_master/session";
    static Session_Type: String = "/event_master/session_type";
    static Social_Login: String = "/user/social_login";
    static Normal_Login: String = "/user/login"
    static Company: string = "/event_master/company";
    static PhoneCode: string = "/master/country_code";
    static DowloadEventQR: string = "/event_master/event/download_qr_zip";
    static DowloadParticipantQR: string = "/event_master/participant/download_qr";
    static ExportEventData: string = "/event_master/participant_export";
    static ImportEventData: string = "/event_master/participant/upload";
    static SaveTemplate: String = "/event_master/event/save_admit_card";
    static Timezone: String = "/event_master/timezone";
    static Template: String = "/event_master/template";
    static AdmitCard: String = "/event_master/admit-card";
    static DownloadAdmitCard: String = "/event_master/admit-card/download_admit_card";





}
