import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ParticipantModel, PhoneCountryCodeResponseModel } from 'src/api-providers/models/participant.model';
import { SessionModel } from 'src/api-providers/models/session.model';
import { ParticipantsViewModel } from 'src/api-providers/view-models/participants.view.model';
import { ModalParticipantFormComponent } from '../modal-participant-form/modal-participant-form.component';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';

@Component({
  selector: 'app-participant-card',
  templateUrl: './participant-card.component.html',
  styleUrls: ['./participant-card.component.scss'],
  providers: [ParticipantsViewModel]
})
export class ParticipantCardComponent {

  @Input() participantModel: ParticipantModel;
  @Input() participantEventDetailId: string = '';
  @Input() eventSessions: SessionModel[] = [];
  @Input() participantAttendance: boolean = false;
  @Input() participantSessionsAttendance: Number[] = [];
  @Input() admitCardIssued: boolean = false;

  // admitCardIssued: boolean = false;


  phoneCountryCodeResponseModel = new BehaviorSubject<PhoneCountryCodeResponseModel>(new PhoneCountryCodeResponseModel());


  constructor(
    private participantVM: ParticipantsViewModel,
  ){
    
  } 

  ngOnInit(){
    this.participantVM.parentParticipantCardComponent = this;
    this.participantVM.subscribeToPhoneCodeModel(this.phoneCountryCodeResponseModel);
    // this.participantVM.getAdmitCard(this.participantEventDetailId)
  }

  // cardReceived(data:any){
  //   this.admitCardIssued = data.results[0].issued;
  // }

  changeParticipantAttendance(e: any){
    this.participantAttendance = e.target.checked;
    this.participantVM.updateAttendance(this.participantEventDetailId, this.participantAttendance)
  }

  changeSessionAttendance(e: any, sessionId: number){
    var check = e.target.checked;
    if(check){
      if(!(this.participantSessionsAttendance.includes(sessionId)))
      this.participantSessionsAttendance.push(sessionId)
    } else {
      this.participantSessionsAttendance = this.participantSessionsAttendance.filter(el => el != sessionId);
    }
    this.participantVM.updateSessions(this.participantEventDetailId, this.participantSessionsAttendance);
    
  }
  
  getPhoneValue(id: number){
    // var el = this.phoneCountryCodeResponseModel.value.results.find(el => el.id == id); 
    // return el?.code
  }

  downloadParticipantQR(participantModel: ParticipantModel){
    var filename = participantModel.first_name + ' ' + participantModel.last_name
    this.participantVM.downloadParticipantQR(this.participantEventDetailId, filename);
  }

  downloadAdmitCard(participantModel: ParticipantModel){
    var filename =  participantModel.first_name + '_' + participantModel.last_name +'_admit'
    this.participantVM.downloadEventAdmitCard(this.participantEventDetailId,filename);
  }

  editParticipant(){
    var participant = this.participantModel;
    if(participant){
      ModalParticipantFormComponent.StaticCall.updateParticipantFields(participant);
    } else {
      ModalParticipantFormComponent.StaticCall.participantModel = undefined;
      ModalParticipantFormComponent.StaticCall.initFormFields();
    }
  }

  deleteParticipant(participantModel: ParticipantModel){
    ModalDeleteComponent.StaticCall.removeParticipantEventDetail = true;
    ModalDeleteComponent.StaticCall.deleteObject = { 
      name: (participantModel.first_name + ' ' + participantModel.last_name), 
      id: this.participantEventDetailId
    };
  }
}
