import { Component, EventEmitter, Output } from '@angular/core';
import {  FormControl, FormGroup,  Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { EventModel, TimezoneResponseModel } from 'src/api-providers/models/event.model';
import { SessionResponseModel } from 'src/api-providers/models/session.model';
import { EventsViewModel } from 'src/api-providers/view-models/events.view.model';

@Component({
  selector: 'app-model-event-form',
  templateUrl: './model-event-form.component.html',
  styleUrls: ['./model-event-form.component.scss'],
})
export class ModelEventFormComponent { 
  
  eventModel!: EventModel; 
  eventForm: FormGroup;
  @Output() formSuccessEvent = new EventEmitter<any>();
  sessionsResponseModel = new BehaviorSubject<SessionResponseModel>(new SessionResponseModel());
  timezoneResponseModel = new BehaviorSubject<TimezoneResponseModel>(new TimezoneResponseModel());

  
  static StaticCall: any;

  constructor(
    private eventVM: EventsViewModel
  ) {
    ModelEventFormComponent.StaticCall = this;
    this.initFormFields();
  }

  ngOnInit():void {
    this.eventVM.parentModelEventFormComponent = this;
    this.eventVM.subscribeToSessionResponseModel(this.sessionsResponseModel);
    this.eventVM.subscribeToTimezoneResponseModel(this.timezoneResponseModel);

    this.eventVM.getSessions();
    this.eventVM.getTimezones();
  }



  updateCompanyFields(event: EventModel){
    this.eventModel = event;
    this.eventForm.setValue({ 
      name: event.name,
      description: event.description,
      event_agenda_url: event.event_agenda_url,
      event_faqs_url: event.event_faqs_url,
      event_format_url: event.event_format_url,
      event_panel_sessions_url: event.event_panel_sessions_url,
      event_sponsors_url: event.event_sponsors_url,
      no_registration_required: event.no_registration_required, 
      session: event.session,
      event_date: event.event_date,
      start_at: event.start_at,
      end_at: event.end_at,
      timezone: event.timezone


    })
  }

  initFormFields(){
    this.eventForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      description: new FormControl('', [Validators.required, Validators.minLength(20)]),
      event_agenda_url: new FormControl('', [Validators.required, Validators.minLength(10)]),
      event_faqs_url: new FormControl('', []),
      event_format_url: new FormControl('', []),
      event_panel_sessions_url: new FormControl('', []),
      event_sponsors_url: new FormControl('', []),
      no_registration_required: new FormControl(false, []),
      session: new FormControl('', []),
      event_date: new FormControl(null, []),
      start_at: new FormControl(null, []),
      end_at: new FormControl(null, []),
      timezone: new FormControl(null, []),

    }); 
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  
  timeError: boolean = false;
  validateEventDate(): boolean {
    // const eventDateControl = this.eventForm.get('event_date');
    const startAtControl = this.eventForm.get('start_at');
    const endAtControl = this.eventForm.get('end_at');
    // const timezoneControl = this.eventForm.get('timezone');

    this.timeError = false;
    if (startAtControl?.value?.length > 0 && (endAtControl?.value == '' || endAtControl?.value == null)){
      this.timeError = true;
      return false;
    }
    if (endAtControl?.value?.length > 0 && (startAtControl?.value == '' || startAtControl?.value == null)){
      this.timeError = true;
      return false;
    }

    return true;

    // if (eventDateControl?.value &&  !timezoneControl?.value ) {
      
    //   timezoneControl?.markAsTouched();
    //   return false
    // }else if(eventDateControl?.value && (!startAtControl?.value || !endAtControl?.value)){
    //   timezoneControl?.markAsUntouched();
    //   startAtControl?.markAsDirty();
    //   endAtControl?.markAsDirty();
    // }else{
    //   timezoneControl?.markAsUntouched();
    //   endAtControl?.markAsUntouched();
    //   startAtControl?.markAsUntouched();
    //   return true

    // }
    // return true

  }

  onSubmit() {
    if (this.eventForm.invalid) {
      this.validateAllFormFields(this.eventForm);
    } else if(this.validateEventDate()) {
      var newModel = new EventModel();
      newModel = this.eventForm.value;

      const start_at: any = newModel.start_at;
      const end_at: any = newModel.end_at;

      // Checking empty 
      if(start_at == '' && end_at == ''){
        newModel.start_at = null;
        newModel.end_at = null
      }

      if(this.eventModel?.slug){
        newModel.slug = this.eventModel.slug;  
      }        
      this.eventVM.createOrUpdateEvent(newModel);
    }
  }

  updatedEventMedel(event: EventModel){
    document.getElementById("eventModalCloseBtn")?.click();
    this.formSuccessEvent.emit(event);
  }



}
