import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observer } from "rxjs";
import { ApiService } from "../../api-providers/services/api-service";
import { ApiServiceDelegate } from "../../api-providers/services/api-service-delegate";
import { ApiCollection } from "../../api-providers/services/api-collection"; 
import { ParticipantEventDetailModel, ParticipantModel, ParticipantResponseModel, PhoneCountryCodeResponseModel } from "../models/participant.model";
import { ParticipantsComponent } from "src/app/admin/participants/participants.component";
import { ModalParticipantFormComponent } from "src/app/components/modal-participant-form/modal-participant-form.component";
import { CompanyResponseModel } from "../models/company.model";
import { AlertService } from "../services/alert.service";
import { ModalDeleteComponent } from "src/app/components/modal-delete/modal-delete.component";
import { LoaderService } from "../services/loader.service";
import { ParticipantCardComponent } from "src/app/components/participant-card/participant-card.component";
  

@Injectable({
    providedIn: "root",
})

export class ParticipantsViewModel {

    parentParticipantsComponent: ParticipantsComponent; 
    parentModalParticipantsFormComponent: ModalParticipantFormComponent;
    parentModalDeleteComponent: ModalDeleteComponent;
    parentParticipantCardComponent: ParticipantCardComponent; 


    companiesResponseModel = new BehaviorSubject<CompanyResponseModel>(new CompanyResponseModel());
    participantsResponseModel = new BehaviorSubject<ParticipantResponseModel>(new ParticipantResponseModel()); 
    phoneCountryCodeResponseModel = new BehaviorSubject<PhoneCountryCodeResponseModel>(new PhoneCountryCodeResponseModel());
    
    constructor(private apiService: ApiService, private alertService: AlertService, private loaderService: LoaderService ) { 
      
    }

    subscribeToViewModel(
        observer: Observer<ParticipantResponseModel>
    ){
        this.participantsResponseModel.subscribe(observer);
    }

    subscribeToCompanyModel( 
      observer: Observer<CompanyResponseModel>
      ){
          this.companiesResponseModel.subscribe(observer);
      }
      
      subscribeToPhoneCodeModel( 
        observer: Observer<PhoneCountryCodeResponseModel>
        ){
            this.phoneCountryCodeResponseModel.subscribe(observer);
        }
    

      getPhoneCodes(){
        var url = ApiCollection.PhoneCode + "?page=1&page_size=100";
        let d: ApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: url,
          Success: (e: any) => {
            this.phoneCountryCodeResponseModel.next(e); 
            return e; 
          },
          Error: (e: String) => { 
            return e;
          },
        };
        this.apiService.callService(d);
      }



    updateSessions(detailId: string, sessions: any){
      this.loaderService.showLoading();
      var url = ApiCollection.Participant_Event_Detail + '/' + detailId;
      let d: ApiServiceDelegate = {
        Data: {sessions: sessions},
        Method: "PATCH",
        Action: url,
        Success: (e: any) => {
          this.alertService.addAlert("Session attendance updated", 'success');
          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.alertService.addAlert("Session attendance not updated", 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
      
    }


    updateAttendance(detailId: string, attendance: boolean){
      this.loaderService.showLoading();
      var url = ApiCollection.Participant_Event_Detail + '/' + detailId;
      let d: ApiServiceDelegate = {
        Data: {
          attendance: attendance
        },
        Method: "PATCH",
        Action: url,
        Success: (e: any) => {
          this.alertService.addAlert("Attendance updated", 'success');
          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.alertService.addAlert("Attendance not updated", 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    getCompanies(){
      var url = ApiCollection.Company + "?page=1&page_size=2000";
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => {
          this.companiesResponseModel.next(e); 
          return e; 
        },
        Error: (e: String) => { 
          return e;
        },
      };
      this.apiService.callService(d);
    }

    deleteParticipant(participantId: string){
      this.loaderService.showLoading();
      var url = ApiCollection.Participant + "/" + participantId;
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "DELETE",
        Action: url,
        Success: (e: any) => {
          this.parentModalDeleteComponent.deleteSuccess();
          this.alertService.addAlert('Participant Deleted', 'success');
          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    getParticipants(name: string, page: number, page_size: number){ 
      this.loaderService.showLoading();
      var url = ApiCollection.Participant + "?page=" + page + "&page_size=" + page_size;
      if(name.length > 0){
        url += "&name=" + encodeURIComponent(name);
      }
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => {
          e.results.forEach((element: any) => {
            if(element.phone != null){
              element.phone = JSON.parse(element.phone);
            } else {
              element.phone = [];
            }
          });
          this.participantsResponseModel.next(e); 
          this.parentParticipantsComponent.participantsReceived();
          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    createOrUpdateParticipant(participant: ParticipantModel){
      this.loaderService.showLoading();
      var data = JSON.parse(JSON.stringify(participant)) 
      var url = ApiCollection.Participant;
      if(participant.id){
        url += "/" + participant.id
      }
      let d: ApiServiceDelegate = {
        Data: data,
        Method: participant.id ? 'PATCH':'POST',
        Action: url,
        Success: (e: any) => {
          this.parentModalParticipantsFormComponent.updatedParticipantMedel(e);
          this.alertService.addAlert(participant.id ? 'Participant updated!':'Participant created!', 'success');
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: any) => { 
          var error = ''
          for (const [key, value] of Object.entries(e.error)) {
            error = String(value)
          }
         
           this.alertService.addAlert(error , 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }


    downloadParticipantQR(slug: string, fileName: string){
      this.loaderService.showLoading();
      this.apiService.downloadImage(ApiCollection.DowloadParticipantQR + '?slug=' + slug).subscribe((e: any) => { 
        function downloadUrl(url: string, fileName: string) {
          const a: any = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.style = 'display: none';
          a.click();
          a.remove();
        };
        const url = URL.createObjectURL(e); 
        downloadUrl(url, (fileName + '.png'));  
        this.loaderService.hideLoading();
      },
        (error: any) => {
          this.loaderService.hideLoading();
          console.log(error);
        }
      );
    }

    downloadEventAdmitCard(slug: string, fileName: string){
      this.loaderService.showLoading();
      this.apiService.downloadImage(ApiCollection.DownloadAdmitCard + '?slug=' + slug).subscribe((e: any) => { 
        function downloadUrl(url: string, fileName: string) {
          const a: any = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.style = 'display: none';
          a.click();
          a.remove();
        };
        const url = URL.createObjectURL(e); 
        downloadUrl(url, (fileName + '.pdf'));  
        this.loaderService.hideLoading();
      },
        (error: any) => {
          this.loaderService.hideLoading();
          console.log(error);
        }
      );
    }

    // getAdmitCard(participantEvent:any){
    //   var url = ApiCollection.AdmitCard 
    //   if (participantEvent){

    //     url += "?participant_event="+participantEvent;
    //   }

    //   let d: ApiServiceDelegate = {
    //     Data: {},
    //     Method: "GET",
    //     Action: url,
    //     Success: (e: any) => {
    //       this.parentParticipantCardComponent.cardReceived(e);
    //       return e; 
    //     },
    //     Error: (e: String) => { 
    //       return e;
    //     },
    //   };
    //   this.apiService.callService(d);

    // }
 


}
