import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdmitCard, TemplateResponseModel } from 'src/api-providers/models/event.model';
import { EventsViewModel } from 'src/api-providers/view-models/events.view.model';

@Component({
  selector: 'app-modal-admit-template',
  templateUrl: './modal-admit-template.component.html',
  styleUrls: ['./modal-admit-template.component.scss']
})
export class ModalAdmitTemplateComponent {
  @Input() eventId: number ;
  @Input() isModalOpen: boolean=false ;

  @Output() formSuccessEvent = new EventEmitter<any>();
  @ViewChild('cardModalCloseBtn') closeBtn!: ElementRef;
  templateResponseModel = new BehaviorSubject<TemplateResponseModel>(new TemplateResponseModel());
  admitcard = new AdmitCard();
  eventTmplate:number | undefined;
  issued:boolean=false;
  templateFrontImage: File | null = null;
  templateBackImage: File | null = null;

  constructor(
    private vm: EventsViewModel
  ){

  }

  ngOnInit():void {
    this.vm.parentModalAdmitCardComponent = this;
    this.vm.subscribeToTemplatesResponseModel(this.templateResponseModel);
    this.vm.getTemplates();
    

  }

  ngOnChanges(changes:SimpleChanges){
    if (changes['eventId']){
      if (this.eventId){

        this.vm.getAdmitCard(this.eventId,'admitModal');
      }
    }
}

  // onFrontFileSelected(event: any) {
  //   this.selectedFrontFile = event.target.files[0];
  // }
  // onBackFileSelected(event: any) {
  //   this.selectedBackFile = event.target.files[0];
  // }

  setImages(){
    if (this.eventTmplate !=undefined && this.templateResponseModel.value.results){
      let template = this.templateResponseModel.value.results.filter(item=>item.id==this.eventTmplate);
      this.templateFrontImage = template[0].front_image;
      this.templateBackImage = template[0].back_image;
    }
  }

  

  admitCardReceived(data:any){
    if (data.results.length >0){

      this.admitcard = data.results[0];
      this.eventTmplate = this.admitcard.template;
      this.issued = this.admitcard.issued;
      this.setImages();
    }
  }

  createOrUpdateAdmitCard(){
    this.admitcard.issued = this.issued;
    if (this.eventTmplate){
      this.admitcard.template = this.eventTmplate;
    }
    this.admitcard.event = this.eventId;
    this.vm.createOrUpdateAdmitCard(this.admitcard);
    this.closeBtn.nativeElement.click();

   
  }


  clearFileInput(){
    this.issued = false;
    this.eventTmplate = undefined;
    this.templateBackImage = null;
    this.templateFrontImage = null;
    this.formSuccessEvent.emit();


  }

  closeClicked(){
    this.clearFileInput();
  }

}
